// src/components/Footer.js

import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">

        <div className="footer-links">
            <a href="/about">About</a>
            <a href="/reviews">Reviews</a>
            <a href="/contact">Contact</a>
        </div>
        <div className='ofsted-logo'>
          <img 
            src="/images/Ofsted-Logo.png" 
            alt="Ofsted Logo"
            className="ofsted-logo" 
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
