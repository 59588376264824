// src/components/Layout.js

import React from 'react';
import { Outlet } from 'react-router-dom'; 
import Header from './Header'; 
import Footer from './Footer'; 
import './Layout.css'; 
import Banner from './Banner';

const Layout = () => {
  return (
    <div className="layout">
      <Header />
      <Banner/>
      <main className="content">
        <Outlet /> {/* Renders child routes */}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
