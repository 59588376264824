import React from 'react';
import { Helmet } from 'react-helmet';
import './About.css'; // Import the CSS file for styling

// Define available times of the day
const timesOfDay = [
  "Before School",
  "Morning",
  "Afternoon",
  "After School",
  "Evening",
  "Overnight",
];

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

// Example availability data
const availability = {
  Monday: ["true", "true", "true", "true", "false", "false"],
  Tuesday: ["true", "true", "true", "true", "false", "false"],
  Wednesday: ["true", "true", "true", "true", "false", "false"],
  Thursday: ["true", "true", "true", "true", "false", "false"],
  Friday: ["false", "false", "false", "false", "false", "false"],
  Saturday: ["false", "false", "false", "false", "false", "false", "false", "false", "false", "false"],
  Sunday: ["false", "false", "false", "false", "false", "false", "false", "false", "false", "false"],
};

const About = () => {
  return (
    <div className="about-page">
      <Helmet>
        <title>Little Steps Childminding | About</title>
        <meta name="description" content="Learn more about Little Steps Childminding, our values, and our Ofsted Outstanding-rated team in Cheshunt, Hertfordshire. We provide a safe, loving space for children to grow." />
        <meta name="keywords" content="Childminding, Cheshunt, Hertfordshire, Ofsted, childcare, special needs, toddler care, early education" />
        <meta property="og:title" content="Little Steps Childminding | About" />
        <meta property="og:description" content="We are Ofsted Outstanding-rated childminders in Cheshunt, Hertfordshire, providing a safe and loving space for children to grow and learn." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.littlestepschildminding.co.uk/about" />
        <link rel="canonical" href="https://www.littlestepschildminding.co.uk/about" />
      </Helmet>
      <h1>About Me</h1>
      <div className='summary'>
        <div className='summary-list'>
        <h2>My Summary</h2>
            <ul>
                <li>Ofsted Registered</li>
                <li>Ofsted <b>Outstanding</b> Rated </li>
                <li>Special Needs Experience</li>
                <li>Paediatric First Aid Trained</li>
                <li>DBS Checked</li>
                <li>Newborn Experience</li>
                <li>Full Driving Licence</li>
                <li>Own a 7-seater Car</li>
                <li>School Pickups </li>
                <li>Childcare Qualifications </li>
                <li>9+ Months Funded Childcare</li>
                <li>2 Year old Funded Childcare </li>
                <li>3 and 4 Year old Funded Childcare</li>
                <li>Tax-Free Childcare</li>
                <li>Flexible Hours</li>
                

            </ul>
        </div>
      </div>
      <br></br>
      <div className='about-me'>
        <h2>About Me</h2>
        <p>I am an Ofsted Registered Childminder and have been for 25 years, I have extensive experience of looking after babies and toddlers, and with children with special needs. I have gained my NVQ3 in Childcare Learning and Development, and update my first aid certificate every 3 years. My hours are currently 7.30 am to 6pm.. During the week we spend our time going to the local park, library, playgroups, indoor adventure areas, meeting friends and other childminders with their children in order to socialize and gain new experiences . We have music and story times as well as cutting and sticking, working with playdough and cooking/baking etc. I have a large garden, with ride on toys, sandpit, playhouse and slide. I also have a dedicated playroom and large dining room for children to play in.</p>

        <p>I take and collect from Bonneygrove school</p>

        <p>I am able to offer free early education for 2, 3 and 4 year olds and use the tax free child scheme</p>

        <p>I am a scout leader for the beaver section and have been for 15 years</p>

        <p>I enjoy helping children to become independent and achieve their next steps whilst having an enjoyable time in a loving and friendly environment</p>

      </div>
      <br></br>
      <div className='about-me'>
        <h2>My Experience</h2>
        <p>I have been childminding for 25 years, my children are aged 29, 26 and 23 and I have looked after many children during this time - some for a few months - some for 11 years. I have experience with special needs children and those with behavioural problems. As my children have grown older i have gained experience of the stages they go through and where to look to for help and advice. I also follow the EYFS scheme.</p>
      </div>
      <br></br>
      <div className='about-me'>
        <h2>My Qualifications</h2>
        <p>NVQ3 in Childcare, Learning and Development. First aid certificate. Ofsted registration. Various childcare related courses including safeguarding.</p>
        <p>Scout Beaver Leader with a group of 6 - 8 year olds.</p>
      </div>
      
      <div className="availability">
        <h2>My Availability</h2>
        <p>Available at short notice and used to fitting in with work patterns.</p>
        <p>Hours 7:30AM - 5:30 PM (flexible)</p>
        
        <div className="availability-grid">
          <div className="grid-header"></div>
          {daysOfWeek.map(day => (
            <div key={day} className="grid-header">{day}</div>
          ))}
          {timesOfDay.map((time, index) => (
            <React.Fragment key={time}>
              <div className="grid-time">{time}</div>
              {daysOfWeek.map(day => (
                <div
                  key={`${day}-${time}`}
                  className={`grid-cell ${availability[day][index] === "true" ? "available" : "not-available"}`}
                >
                  {availability[day][index] === "true" ? "✔️" : "✘"}
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="location-map my-4">
        <h2 className="text-center">Our Location</h2>
        <iframe
          title="Google Map"
          className="w-100"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2624.9366944529625!2d-0.0506042303351527!3d51.70149107727385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1723175308458!5m2!1sen!2suk"
          height="450"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
    
  );
};

export default About;
