import React from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Contact.css';

const Contact = () => {
  return (
    <div className="container contact-page">
      <Helmet>
        <title>Little Steps Childminding | Contact</title>
        <meta name="description" content="Get in touch with Little Steps Childminding in Cheshunt, Hertfordshire. We're here to answer any questions about our Ofsted Outstanding-rated childminding services." />
        <link rel="canonical" href="https://www.littlestepschildminding.co.uk/contact" />
      </Helmet>
      <h1 className="text-center my-4">Contact Us</h1>
      <p className="text-center mb-4">We would love to hear from you! Please reach out to us through any of the following methods:</p>

      <div className="row">
        <div className="col-md-6 contact-info">
          <h2>Contact Information</h2>
          <p><strong>Phone: </strong><a href="tel:+447986564333">07986 564333</a></p>
          <p><strong>Email: </strong><a href="mailto:fletcher.debbie@hotmail.com">fletcher.debbie@hotmail.com</a></p>
          <p><strong>Hours: </strong>Monday - Friday, 7:30 AM - 5:30 PM</p>
        </div>

        <div className="col-md-6 contact-form">
          <h2>Send Us a Message</h2>
          <form
            action="https://formspree.io/f/mrbznpwe"
            method="POST"
          >
            <label>
              Your name:
              <input type="text" name="name" required className="form-control" />
            </label>
            <label>
              Your phone:
              <input type="tel" name="phone" className="form-control" />
            </label>
            <label>
              Your email:
              <input type="email" name="email" required className="form-control" />
            </label>
            <label>
              Your message:
              <textarea name="message" required className="form-control"></textarea>
            </label>

            <button type="submit" className="btn btn-primary">Send</button>
          </form>
        </div>
      </div>

      
    </div>
  );
};

export default Contact;
