import React from 'react';
import { Helmet } from 'react-helmet';
import './Home.css'; // Importing the corresponding CSS

const Home = () => {
  return (
    <div className="home-page">
      <Helmet>
        {/* Basic SEO */}
        <title>Little Steps Childminding | Ofsted Outstanding Rated Childminding</title>
        <meta name="description" content="Little Steps Childminding in Cheshunt, Hertfordshire offers a nurturing, Ofsted Outstanding-rated environment for children's growth and development. Contact us for top-quality childminding services." />
        <link rel="canonical" href="https://www.littlestepschildminding.co.uk/" />

        {/* Open Graph for social media sharing */}
        <meta property="og:title" content="Little Steps Childminding | Ofsted Outstanding Childminding" />
        <meta property="og:description" content="Providing a loving and nurturing environment for children's growth. Located in Cheshunt, Hertfordshire." />
        <meta property="og:url" content="https://www.littlestepschildminding.co.uk/" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />

        {/* Additional meta tags for SEO */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Little Steps Childminding" />
      </Helmet>
      
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Welcome to Little Steps Childminding</h1>
          <p>Providing a loving and nurturing environment for your child's growth and happiness.</p>
          <a href="/contact" className="contact-button">Contact Us</a>
        </div>
      </section>

      {/* Info Section */}
      <section className="info-section">
        <div className="info-content">
          <h1>A little about us</h1>
          <p>We are an Ofsted Outstanding Rated childminding business based in Cheshunt, Hertfordshire.</p>
          <a href="/about" className="about-button">About Us</a>
          <a href="https://files.ofsted.gov.uk/v1/file/50220848" className="about-button" target="_blank" rel="noopener noreferrer">Read our Outstanding Ofsted Review</a>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials-section">
        <h2>What Parents Say</h2>
        <div className="testimonials-list">
          <div className="testimonial-item">
            <p>"Debbie is absolutely wonderful with our kids. We couldn't ask for a better childminder!"</p>
            <span>- Kim</span>
          </div>
          <div className="testimonial-item">
            <p>"My son loves spending time with Debbie, and I feel confident he's in great hands."</p>
            <span>- Carina</span>
          </div>
        </div>
        <a href="/reviews" className="testimonial-button">See more reviews here</a>
      </section>
    </div>
  );
};

export default Home;
